@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&family=Zen+Kaku+Gothic+New&display=swap');

:root {
  --black-color: #010000;
  --white-color: #ffffff;
  --lightgray-color: #e6e6e6;
}
html {
  background-color: #111111;

}
div {
  background-color: #111111;
}

::selection {
  background-color: #f82f2f; 
  color: #3500d3;
}

.App {
  font-size: 18px;
  height: 100vh;
  width: 100%;
}

.container {
  height: 100vh;
  padding: 120px;
}

/* Header */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  height: 90px;
  position: fixed;
  z-index: 10;
}

header .title h3 {
  font-family: 'Ubuntu', sans-serif;
  margin-left: 30px; 
  color: rgb(255, 255, 255);
}

header .title {
  text-decoration: none; 
}

nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
}

nav ul li a {
  font-family: 'Ubuntu', sans-serif;
  font-size: 19px;
  text-decoration: none;
  color: var(--white-color);
  transition: all 0.2s;
}
.header-link {
  color: #f8f9fa;
}
.header-link:hover {
  color: #0044ff;
}

.header-title{
  text-decoration: none;

}

.nav-toggle {
  background: none;
  display: none;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  margin-right: 30px;
}

.nav-toggle .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: #ffffff;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.nav.expanded .bar {
  transform: rotate(45deg);
  position: relative;
  z-index: 1;
}

.nav.expanded .bar:nth-of-type(2) {
  opacity: 0;
}

.nav.expanded .bar:nth-of-type(3) {
  transform: rotate(-45deg);
}

@media (max-width: 768px) {
  .App {
    font-size: 16px;
    height: 100vh;
    width: 100%;
  }


  
  .nav-toggle {
    display: block;
    z-index: 2;
  }
  
  .nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #010000d3;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 1;
  }
  
  .nav.expanded {
    opacity: 1;
    visibility: visible;
  }

  .nav ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  
  .nav li {
    padding: 15px;
    width: 100%;
  }
  
  .nav li a {
    color: #fff;
    text-decoration: none;
    display: block;
    font-size: 24px; 
  }
  
  .menu-icon.close {
    position: relative;
    display: block;
    width: 30px;
    height: 4px;
    background-color: transparent;
    cursor: pointer;
  }
  
  .menu-icon.close::before,
  .menu-icon.close::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px; /* バツ印の太さ */
    background-color: #fff; /* バツ印の色 */
    top: 0%;
    left: 0%;
    transform-origin: center;
  }
  
  .menu-icon.close::before {
    transform: rotate(45deg);
  }
  
  .menu-icon.close::after {
    transform: rotate(-45deg);
  }
  .nav-toggle, .nav-toggle span, .menu-icon.close::before, .menu-icon.close::after {
    transition: all 0.1s ease;
  }
  
}


/* About */

.about-title{
  font-family: 'Ubuntu', sans-serif;
}

.profile-jp{
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

.profile-en{
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

.sns a {
  color: white;
  font-family: 'Zen Kaku Gothic New', sans-serif;    
}

.mail a {
  color: white;
  font-family: 'Zen Kaku Gothic New', sans-serif;    
}

.sns img {
  width: 32px;
  height: 32px; 
}
.sns li {
  list-style: none;

}

.sns ul {
  display: flex;
  gap:20px;
  margin: 0;
  padding: 0;
}

/* Contact */
.mail {
  text-decoration: underline;
}



/* Projects */
.card-aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9のアスペクト比 */
}

.card-aspect-ratio img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-rounded {
  border-radius: 2px;
}

.image-overlay-container {
  position: relative;
}
.noise-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><filter id="noiseFilter"><feTurbulence type="fractalNoise" baseFrequency="1.5" numOctaves="3" stitchTiles="stitch"/></filter><rect width="100%" height="100%" filter="url(#noiseFilter)"></rect></svg>');
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center; 
}

.image-overlay-container:hover .noise-overlay {
  opacity: 1;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2c262694;
  opacity: 0; 
  display: flex;
  align-items: center; 
  justify-content: center;
  transition: opacity 0.3s ease;
  padding: 10px;
  text-align: center; 
  border-radius: 2px;
}

.image-overlay-container:hover .image-overlay {
  opacity: 1;
}
.project-img-title{
  font-family: 'Ubuntu', sans-serif;
}

.project-title{
  font-family: 'Ubuntu', sans-serif;
  color: #f9f9f9;
}

/*ProjectTemplate*/
.project-title{
  font-family: 'Ubuntu', sans-serif;
}

.descliption-jp{
  color: #f8f9fa;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

.descliption-en{
  color: #f8f9fa;
  font-family: 'Zen Kaku Gothic New', sans-serif;
}

.date{
  color: #f8f9fa;
}

.rubi{
  color: #f8f9fa;
  font-size: smaller;
}

.credit{
  color: #f8f9fa;
}

.small-image {
  width: 100%; /* コンテナの幅に合わせて画像を拡大 */
  height: auto; /* 縦横比を保持 */
}

.half-size {
  width: 50%;
}
